import { Checkbox } from '@mui/material';
import styled from 'styled-components';
import { theme } from 'theme';
import { ConfirmDialog, Text, FlexBox } from 'components/base';
import { companyResourceSelector } from 'domain/company/selectors';
import { purchaseCreditsAction } from 'domain/credits/actions';
import { creditResourceSelector } from 'domain/credits/selectors';
import { useAppDispatch } from 'domain/store';
import { formatCurrency } from 'helpers/number';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Address } from 'types/credits';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';

interface OwnProps {
  amount: number;
  creditsNeeded: { total: number; toBePurchased: number };
  visible: boolean;
  setVisible: (open: boolean) => void;
  handleUpdateJobStatus: (payload: Record<string, unknown>) => void;
}
const PurchaseCreditsModal: React.FC<OwnProps> = ({
  amount,
  creditsNeeded,
  visible,
  setVisible,
  handleUpdateJobStatus,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const credits = useSelector(creditResourceSelector);
  const companyDetails = useSelector(companyResourceSelector);
  const defaultCreditPrice = 895;

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const [selectedBox, setSelectedBox] = useState(0);

  const creditBoxes = [
    creditsNeeded.toBePurchased,
    Math.floor(creditsNeeded.toBePurchased * 1.5),
    Math.floor(creditsNeeded.toBePurchased * 2),
  ];

  const purchaseAndConfirm = () => {
    if (!isTermsAccepted) {
      setShowTermsError(true);
      return;
    }

    if (!companyDetails?.subscription.perCreditsPrice) {
      toast.error(formatMessage({ id: IntlKeys.creditErrorLoadPrice }));
      return;
    }

    dispatch(
      purchaseCreditsAction({ amount: creditBoxes[selectedBox], address: companyDetails?.billingAddress as Address }),
    )
      .unwrap()
      .then(() => {
        const payload = {
          maxCandidates: amount,
        };
        handleUpdateJobStatus(payload);
      });
  };

  const selctPurchaseCreditBox = (index: number) => {
    setSelectedBox(index);
  };

  const acceptOrRejectTerms = () => {
    setIsTermsAccepted((isAccepted) => !isAccepted);
  };

  const currentAmount = (credits?.currentAmount || 0) - (credits?.reservedAmount || 0);

  return (
    <ConfirmDialog
      isOpen={visible}
      onConfirm={purchaseAndConfirm}
      onCancel={() => {
        setVisible(false);
      }}
      title={formatMessage({ id: IntlKeys.creditStartRecruiting })}
      description={formatMessage(
        { id: IntlKeys.creditTooLess },
        { currentAmount, creditsNeeded: creditsNeeded.toBePurchased },
      )}
    >
      <ConfirmModal>
        <Text>{formatMessage({ id: IntlKeys.creditSelectAmountHead })}</Text>
        {companyDetails?.subscription?.perCreditsPrice ? (
          <CreditBoxes>
            {creditBoxes.map((credit, index) => (
              <EachBox key={credit} onClick={() => selctPurchaseCreditBox(index)} isSelected={selectedBox === index}>
                <EachRow>
                  <Label>{formatMessage({ id: IntlKeys.credits })}:</Label> <Value>{credit}</Value>
                </EachRow>
                <EachRow>
                  <Label>{formatMessage({ id: IntlKeys.creditPerCreditPrice })}:</Label>
                  <Value>{formatCurrency(companyDetails?.subscription.perCreditsPrice || defaultCreditPrice)}</Value>
                </EachRow>
                <EachRow>
                  <Label>{formatMessage({ id: IntlKeys.creditPriceTotal })}:</Label>{' '}
                  <Value>
                    {formatCurrency(credit * (companyDetails?.subscription.perCreditsPrice || defaultCreditPrice))}
                  </Value>
                </EachRow>
              </EachBox>
            ))}
          </CreditBoxes>
        ) : (
          <CreditLoadError>{formatMessage({ id: IntlKeys.creditErrorLoadPrice })}</CreditLoadError>
        )}
        <TandCheck>
          <Checkbox checked={isTermsAccepted} onChange={acceptOrRejectTerms} />
          {formatMessage({ id: IntlKeys.creditsIAgree }) + ' '}
          <a href="https://talentsandfriends.de/privacy-policy" target={'_blank'} rel="noreferrer">
            {formatMessage({ id: IntlKeys.termsConditions })}
          </a>
        </TandCheck>
        {showTermsError && !isTermsAccepted && (
          <ErrorText>{formatMessage({ id: IntlKeys.termsConditionsError })}</ErrorText>
        )}
      </ConfirmModal>
    </ConfirmDialog>
  );
};
export default PurchaseCreditsModal;

const ConfirmModal = styled.div`
  max-width: 768px;
  width: 100%;
  padding: 1rem;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    padding: 1rem 0rem;
  }
`;

const CreditBoxes = styled(FlexBox)`
  display: flex;
  max-width: 800px;
  margin: auto;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 1rem;
  gap: 0.5rem;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

const EachBox = styled.div`
  padding: 0.5rem 1rem;
  border: ${(props: { isSelected: boolean }) =>
    props.isSelected ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.gray}`};
  border-radius: 10px;
  font-size: 1.25rem;
  color: ${(props: { isSelected: boolean }) => (props.isSelected ? theme.colors.white : theme.colors.secondary)};
  background: ${(props: { isSelected: boolean }) => (props.isSelected ? theme.colors.primary : 'transparent')};
  cursor: pointer;
  flex: 1;
  width: 100%;
`;

const EachRow = styled(FlexBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0rem;
`;

const Label = styled.span`
  text-align: left;
  font-size: 0.75rem;
`;

const Value = styled.span`
  font-weight: 530;
  font-size: 1rem;
`;

const ErrorText = styled.p`
  color: ${theme.colors.red};
`;

const TandCheck = styled.div`
  a {
    color: ${theme.colors.primary};
    text-decoration: none;
    outline: none;
  }
`;

const CreditLoadError = styled.div`
  margin: 2rem;
  padding: 3rem;
  border-radius: 30px;
  background: white;
  border: 1px solid ${theme.colors.error};
  color: ${theme.colors.error};
  font-size: 1.25rem;
`;
